/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, of, pairwise, Subject, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { Cluster, ConnectionStatus, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { filter, map, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { IncomingRTCSessionEvent, UA, UnRegisteredListener } from 'jssip/lib/UA';
import * as JsSIP from 'jssip';
import { WebSocketInterface } from 'jssip';
import { RTCPeerConnectionDeprecated, RTCSession } from 'jssip/lib/RTCSession';
import { updateMediaConnectionRecord, updateMediaConnectionsExtraHeaders } from '../+state/media.actions';
import {
    selectClusterConnectionStatus,
    selectCtcAvailability,
    selectInitializedMediaConnection,
    selectMediaConnectionPassword,
    selectOrphanedSessions
} from '../+state/media.selectors';
import { MediaConnection, SessionState } from '../../core/model/media-connection';
import { selectHeadsetConnected } from '../+state/cchub.selectors';
import { SortFunctions } from '../util/sort-functions';
import { displayToastNotification } from '../../notification/+state/notification.actions';
import { ToastType } from '@msi/cobalt';
import { selectClusterConfiguration, selectConnectionRecoverPeriod, selectHeadsetRecoverPeriod } from '../../configuration/+state/configuration.selectors';
import { EnvironmentService } from '../../core/services/environment.service';
import { selectHasActiveInboundVoiceCall, selectWebsocketDisconnected } from '../+state/call.selectors';
import { selectMemoizedUserAuthenticationRecord } from '../../user/+state/user.selectors';
import { sipPeerPasswordRequest } from '../../configuration/+state/configuration.actions';

@Injectable({
    providedIn: 'root'
})
export class MediaService implements OnDestroy {
    private static readonly ASTERISK_WEBSOCKET_PATH = 'ws';

    // Audio
    private localMediaStream: MediaStream | undefined;
    public remoteMediaStream$ = new BehaviorSubject<MediaStream | undefined>(undefined);
    private audio = new Audio();

    private connections: UA[] = [];
    public session!: RTCSession | null;
    public peerConnection$ = new BehaviorSubject<RTCPeerConnectionDeprecated | undefined>(undefined);
    protected unsubscribe$ = new Subject<void>();
    private headsetTimeout!: number;
    private headsetRecoverPeriod = 0;
    private connectionRecoverPeriod = 10000;
    private unregisterTimeouts: { [key: string]: number } = {};
    private retryTimeouts: { [key: string]: number } = {};
    private optionsTimeouts: { [key: string]: number } = {};
    private optionsPeriod: number = 5000;
    private isInbound: boolean = false;

    constructor(
        private store: Store,
        private env: EnvironmentService
    ) {
        this.audio.autoplay = true;
        this.audio.muted = true;

        this.store
            .select(selectHeadsetRecoverPeriod)
            .pipe(filter((val) => !!val))
            .subscribe((headsetRecoverPeriod) => (this.headsetRecoverPeriod = headsetRecoverPeriod * 1000));
        this.store
            .select(selectConnectionRecoverPeriod)
            .pipe(filter((val) => !!val))
            .subscribe((connectionRecoverPeriod) => (this.connectionRecoverPeriod = connectionRecoverPeriod * 1000));

        this.store
            .select(selectHasActiveInboundVoiceCall)
            .subscribe((isInbound) => this.isInbound = isInbound);

        this.store.select(selectMemoizedUserAuthenticationRecord)
            .pipe(filter((val) => !!val))
            .subscribe((user) =>
                this.store.dispatch(updateMediaConnectionsExtraHeaders({ headers: MediaService.getExtraHeaders(user) })));

        this.store.select(selectClusterConfiguration)
            .pipe(filter((clusters) => Boolean(clusters.length)), take(1))
            .subscribe((clusters) => clusters.forEach((cluster) =>
                this.store
                    .pipe(selectInitializedMediaConnection(cluster.uuid), take(1))
                    .subscribe((mediaConnection) => this.initializeMediaConnection(mediaConnection))
            ));

        this.store
            .select(selectOrphanedSessions)
            .pipe(filter((orphanedSessions) => Boolean(this.session && orphanedSessions.includes(this.session.id))))
            .subscribe(() => this.destroySession());

        this.store.select(selectHeadsetConnected).subscribe((headsetConnected) => {
            if (this.session?.connection) {
                if (headsetConnected && this.headsetTimeout) {
                    window.clearTimeout(this.headsetTimeout);
                    this.headsetTimeout = 0;
                } else {
                    let errorMessage = `All Headsets Disconnected. You have ${this.headsetRecoverPeriod / 1000} seconds to reconnect your
                    headset before the call is released ${this.isInbound ? 'and requeued, if required' : ''}.`;
                    console.error(errorMessage);
                    this.headsetTimeout = window.setTimeout(
                        () => this.session?.terminate({ status_code: 410, reason_phrase: 'Gone', cause: 'Unavailable' }),
                        this.headsetRecoverPeriod
                    );
                    this.store.dispatch(
                        displayToastNotification({
                            level: ToastType.error,
                            message: errorMessage
                        })
                    );
                }
            }
        });

        let websocketDisconnected$ = this.store.select(selectWebsocketDisconnected)
            .pipe(
                switchMap((c) => c ? timer(2000).pipe(map((() => true))) : of(false)),
                pairwise());

        // Display connection re-established if previously down for x seconds
        websocketDisconnected$.pipe(
            filter(([prev, curr]) => prev === true && curr === false))
            .subscribe(() => {
                this.store.dispatch(
                    displayToastNotification({
                        level: ToastType.success,
                        message: `Data connection re-established.`
                    })
                );
            });

        // Display connection lost message if down for x seconds
        websocketDisconnected$.pipe(
            filter(([prev, curr]) => prev === false && curr === true),
            withLatestFrom(this.store.select(selectWebsocketDisconnected)),
            filter(([, disconnected]) => disconnected)
        ).subscribe(() => {
            let errorMessage = `Data connection lost. ${this.session?.connection ? 'Call will be requeued, if required in ' + (this.connectionRecoverPeriod / 1000) + ' seconds.' : ''}`;
            console.error(errorMessage);
            this.store.dispatch(
                displayToastNotification({
                    level: ToastType.error,
                    message: errorMessage
                })
            );
        });

        window.onbeforeunload = () => {
            this.session?.terminate({ status_code: 410, reason_phrase: 'Gone', cause: 'Unavailable' });
            this.connections.forEach((ua) => {
                ua.stop();
            });
        };
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initializeMediaConnection(mediaConnection: MediaConnection) {
        let ws = new WebSocketInterface(mediaConnection.socket);
        const ua = new JsSIP.UA({
            ...mediaConnection.configuration,
            sockets: ws,
            register: false
        });
        this.handleDoubleCrlfKeepAlives(ua, ws);
        ua.on('connected', (event) => {
            console.debug(`${mediaConnection.clusterLabel} asterisk websocket connected: ${event.socket.url}`);
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, registered: ua.isRegistered(), connected: ua.isConnected() }
                })
            );
        });
        ua.on('disconnected', (event) => {
            console.warn(`${mediaConnection.clusterLabel} asterisk websocket disconnected: ${event.socket.url}`);
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, registered: ua.isRegistered(), connected: ua.isConnected() }
                })
            );
            this.cancelOptions(mediaConnection);
        });
        ua.on('registered', (event) => {
            console.debug(`${mediaConnection.clusterLabel} registered: ${event.response.reason_phrase}`);
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, registered: ua.isRegistered(), connected: ua.isConnected() }
                })
            );
            this.scheduleOptions(ua, mediaConnection);
        });
        ua.on('unregistered', (event) => {
            console.warn(`${mediaConnection.clusterLabel} unregistered: ${event.cause ? event.cause : event.response ? event.response.reason_phrase : ''}`);
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, registered: ua.isRegistered(), connected: ua.isConnected() }
                })
            );
            this.cancelOptions(mediaConnection);
        });
        ua.on('newRTCSession', (evt: IncomingRTCSessionEvent) => {
            console.debug(`Websocket newRTCSession callback invoked`);
            this.handle_invite(evt, mediaConnection);
        });
        this.connections.push(ua);
        ua.start();
        this.monitorCtcAvailability(ua, mediaConnection);
        this.monitorSipPeerPasswordUpdates(ua, mediaConnection);
    }

    private monitorSipPeerPasswordUpdates(ua: UA, mediaConnection: MediaConnection) {
        this.store.select(selectMediaConnectionPassword(mediaConnection.uuid))
            .pipe(filter((val) => !!val), skip(1), tap((password) => console.debug(`${mediaConnection.clusterLabel} sip password updated: ${password}`)))
            .subscribe((password) => ua.set('password', password));
        // monitor ctc connections to refresh sip-password in the event of ctc pod failure or restart
        this.store.select(selectCtcAvailability(mediaConnection.uuid))
            .pipe(filter(({ available }) => available), skip(1))
            .subscribe(({ clusterName }) => this.store.dispatch(sipPeerPasswordRequest({ clusterName: clusterName })));
    }

    private monitorCtcAvailability(ua: UA, mediaConnection: MediaConnection) {
        // inform user of cluster specific connection issues
        this.store.select(selectCtcAvailability(mediaConnection.uuid)).subscribe(({ available }) => {
            if (!available) {
                // @ts-ignore
                let activeSession = ua._sessions[this.session?.id] && !ua._sessions[this.session?.id]?.isEnded();
                let errorMessage = `${mediaConnection.clusterLabel} connection failed. Attempting to reconnect. ${activeSession ? 'Call will be ' +
                    (this.isInbound ? 'requeued, if required in ' : 'terminated in ') + this.connectionRecoverPeriod / 1000 + ' seconds.' : ''}`;
                console.error(errorMessage);
                this.store.dispatch(
                    displayToastNotification({
                        level: ToastType.error,
                        message: errorMessage
                    })
                );
            } else if (this.unregisterTimeouts[mediaConnection.uuid]) {
                this.store.dispatch(
                    displayToastNotification({
                        level: ToastType.success,
                        message: `${mediaConnection.clusterLabel} connection confirmed.`
                    })
                );
            }
        });

        let retryRegistrationListener: UnRegisteredListener = (event) => {
            if (ua.isConnected() && !this.retryTimeouts[mediaConnection.uuid]) {
                console.warn(`${mediaConnection.clusterLabel} registration failed ${event.cause ? event.cause : event.response ? event.response.reason_phrase : ''}`);
                console.debug(`Scheduling registration retry for ${mediaConnection.clusterLabel}`);
                this.retryTimeouts[mediaConnection.uuid] = window.setTimeout(() => {
                    console.debug(`Attempting to register: ${mediaConnection.clusterLabel}`);
                    ua.register();
                    this.retryTimeouts[mediaConnection.uuid] = 0;
                }, 5000);
            }
        };

        // monitor for ctc connection issues and register/un-register as appropriate
        this.store.select(selectClusterConnectionStatus(mediaConnection.uuid)).subscribe(({ http, ctcWebsocket, astWebsocket}) => {
            if (!ua.isRegistered() && http && ctcWebsocket && astWebsocket) {
                console.debug(`Attempting to register: ${mediaConnection.clusterLabel}`);
                ua.addListener('registrationFailed', retryRegistrationListener);
                ua.register();
            }
            if (this.unregisterTimeouts[mediaConnection.uuid] && http && ctcWebsocket) {
                console.debug(`Clearing scheduled un-registration: ${mediaConnection.clusterLabel}`);
                window.clearTimeout(this.unregisterTimeouts[mediaConnection.uuid]);
                this.unregisterTimeouts[mediaConnection.uuid] = 0;
            }
            if (ua.isRegistered() && !this.unregisterTimeouts[mediaConnection.uuid] && !(http && ctcWebsocket)) {
                console.warn(`Scheduled un-registration: ${mediaConnection.clusterLabel}`);
                this.unregisterTimeouts[mediaConnection.uuid] = window.setTimeout(() => {
                    console.warn(`Unregistering: ${mediaConnection.clusterLabel}`);
                    ua.removeListener('registrationFailed', retryRegistrationListener);
                    this.retryTimeouts[mediaConnection.uuid] = 0;
                    ua.terminateSessions({ status_code: 410, reason_phrase: 'Gone', cause: 'Unavailable' });
                    ua.unregister();
                    this.unregisterTimeouts[mediaConnection.uuid] = 0;
                }, this.connectionRecoverPeriod);
            }
        });
    }

    public static getExtraHeaders(user: UserAuthenticationRecord) {
        return [
            `X-MSI-Username: ${user.username}`,
            `X-MSI-Extension: ${user.extension}`,
            `X-MSI-Position: ${user.positionNumber}`,
            `X-MSI-Queues: ${user.associatedCallQueues}`,
            `X-MSI-Position-Name: ${user.positionName}`,
            `X-MSI-Position-Site: ${user.positionSite}`
        ];
    }

    public static getSocketUrl(cluster: Cluster) {
        return new URL(MediaService.ASTERISK_WEBSOCKET_PATH, `wss://${cluster.asteriskAddress}:${cluster.asteriskWebsocketPort}`).href;
    }

    /** Sets (or updates) this media stream associated with the peer connection senders, replacing existing tracks and adding new ones
     * trigger scheduled representation on mic unplug by terminating session with 410 Gone status code.
     * **/
    public setLocalMediaStream(localMediaStream: MediaStream) {
        if (this.session?.connection) {
            const existingSenders = this.session.connection.getSenders().filter((senders) => senders.track && senders.track.kind === 'audio');
            localMediaStream.getAudioTracks().forEach((track, index) => {
                if (existingSenders[index]) {
                    console.debug(`Replacing audio track ${existingSenders[index].track?.label} at index: ${index}`);
                    existingSenders[index]
                        .replaceTrack(track)
                        .then(() => console.debug(`Successfully replaced audio track at index: ${index} with ${track.label}`))
                        .catch((err) => console.error('Failed to replace track: {}', err));
                } else {
                    console.debug(`Adding new local media stream audio track ${track.label}`);
                    this.session?.connection.addTrack(track, localMediaStream);
                }
            });
        }
        this.localMediaStream = localMediaStream;
    }

    private setRemoteMediaStream(remoteMediaStream: MediaStream | undefined) {
        if (remoteMediaStream) {
            this.chromeBugWorkaround(remoteMediaStream);
            this.audio.srcObject = remoteMediaStream;
            this.audio.play();
        }
        this.remoteMediaStream$.next(remoteMediaStream);
    }

    // Chromium bug requires an audio element to 'drive the audio'
    // ref: https://bugs.chromium.org/p/chromium/issues/detail?id=933677
    private chromeBugWorkaround(remoteMediaStream: MediaStream) {
        new Audio().srcObject = remoteMediaStream;
    }

    private handle_invite(event: IncomingRTCSessionEvent, mediaConnection: MediaConnection) {
        if (event.request.getHeader('Alert-Info') === 'Auto Answer') {
            if (this.session) {
                console.error(`Received Auto Answer RTCSession Invite but still have local session. Responding Busy.`);
                event.session.terminate({
                    status_code: 486,
                    reason_phrase: 'Busy Here'
                });
            } else {
                this.session = event.session;
                this.sessionCallbacks(this.session, mediaConnection, event.request.getHeader('X-CTC-Call-Id'));
                console.debug(`Received Auto Answer RTCSession Invite and am attempting to auto-answer.`);
                this.session.answer({
                    mediaStream: this.localMediaStream,
                    pcConfig: {
                        iceServers: this.env.environment.STUN_URL ? [{ urls: this.env.environment.STUN_URL }] : undefined
                    }
                });
            }
        } else {
            console.error(`Received RTCSession Invite with no Auto Answer flag. Ignoring invite.`);
        }
    }

    private sessionCallbacks(session: RTCSession, mediaConnection: MediaConnection, callId: string) {
        session.on('peerconnection', () => {
            console.debug(`Handling RTCSession peerconnection callback.`);
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, session: { id: session.id, callId: callId, state: SessionState.CREATED } }
                })
            );
            this.peerConnectionCallbacks(session.connection);
            this.peerConnection$.next(session.connection);
        });
        session.on('accepted', () => {
            console.debug(`Handling RTCSession accepted callback.`);
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, session: { id: session.id, callId: callId, state: SessionState.ACCEPTED } }
                })
            );
        });
        session.on('confirmed', () => {
            console.debug(`Handling RTCSession confirmed callback.`);
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, session: { id: session.id, callId: callId, state: SessionState.CONFIRMED } }
                })
            );
        });
        session.on('ended', (event) => {
            console.debug(`Handling RTCSession ended callback. Cause: ${event.cause}`);
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, session: { id: session.id, callId: callId, state: SessionState.ENDED } }
                })
            );
            this.setRemoteMediaStream(undefined);
            if (event.originator === 'local' && event.cause === 'Unavailable') {
                this.store.dispatch(displayToastNotification({ level: ToastType.error, message: `Your call on ${mediaConnection.clusterLabel} has been terminated.` }));
            }
            this.session = null;
        });
        session.on('failed', (event) => {
            console.error(
                `Handling RTCSession failed callback.\n Cause: ${event.cause}.\n Originator: ${event.originator}.\n Message: ${event.message?.toString()}`
            );
            this.store.dispatch(
                updateMediaConnectionRecord({
                    mediaConnection: { uuid: mediaConnection.uuid, session: { id: session.id, callId: callId, state: SessionState.FAILED } }
                })
            );
            console.error(`Session status in failed handler: ${this.session?.status}\n Session ended?: ${this.session?.isEnded()}`);
            this.setRemoteMediaStream(undefined);
            this.session = null;
        });
        if (this.env.environment.STUN_URL) {
            session.on('icecandidate', function (event) {
                if (event.candidate.type === 'srflx' && event.candidate.relatedAddress !== null && event.candidate.relatedPort !== null) {
                    event.ready();
                }
            });
        }
    }

    private peerConnectionCallbacks(peerConnection: RTCPeerConnectionDeprecated) {
        peerConnection.ontrack = (event) => {
            console.debug('Adding audio track from remote peer.');
            let mediaStreams = [...event.streams]?.filter((stream) => stream.getAudioTracks()?.length);
            if (mediaStreams && mediaStreams.length) {
                this.setRemoteMediaStream(new MediaStream(mediaStreams.map((mediaStream) => mediaStream.getAudioTracks()).flat()));
            } else {
                console.error(`Received a ${event.track?.kind} track without an associated media stream or audio track.`);
            }
        };
    }

    public static mediaDevicePrioritySort(a: MediaDeviceInfo, b: MediaDeviceInfo) {
        return SortFunctions.compositeSort([MediaService.mediaDeviceCommunicationsSort, MediaService.mediaDeviceDefaultSort], a, b);
    }

    public static mediaDeviceDefaultSort(a: MediaDeviceInfo, b: MediaDeviceInfo) {
        return Number(b.deviceId === 'default') - Number(a.deviceId === 'default');
    }

    public static mediaDeviceCommunicationsSort(a: MediaDeviceInfo, b: MediaDeviceInfo) {
        return Number(b.deviceId === 'communications') - Number(a.deviceId === 'communications');
    }

    /** jsSip does not currently handle double CRLF keep alive messages.
     * To properly handle double CRLF keep alive messages from asterisk (and respond) we directly overload the internal Transport.js _onData method.
     *  ref: cloned version jssip: 3.10.0
     *  ref: https://datatracker.ietf.org/doc/html/rfc7118#section-6
     *  ref: https://datatracker.ietf.org/doc/html/rfc5626#section-3.5.1
     *  ref: https://github.com/versatica/JsSIP/pull/791 **/
    private handleDoubleCrlfKeepAlives(ua: UA, ws: WebSocketInterface) {
        // @ts-ignore
        const superOnData = ua._transport._onData.bind(ua._transport);
        // @ts-ignore
        ua._transport._onData = (data) => {
            if (data === '\r\n\r\n') {
                try {
                    ws.send('\r\n');
                } catch (error) {
                    console.warn(`Error sending Keep Alive response: ${error}`);
                }
                return;
            }
            superOnData(data);
        };
    }

    public static getConnectionStatus(connection: MediaConnection): ConnectionStatus {
        if (connection.connected && connection.registered && connection.session?.state === SessionState.CONFIRMED) {
            return 'ON_CALL';
        }
        if (connection.connected && connection.registered) {
            return 'REGISTERED';
        }
        if (connection.connected) {
            return 'CONNECTED';
        }
        return 'DISCONNECTED';
    }

    private scheduleOptions(ua: UA, mediaConnection: MediaConnection) {
        console.debug(`Scheduling client options on ${mediaConnection.clusterLabel} every ${this.optionsPeriod/1000}s`);
        this.optionsTimeouts[mediaConnection.name] = window.setInterval(() => this.sendOptions(ua, mediaConnection), this.optionsPeriod);
    }

    private cancelOptions(mediaConnection: MediaConnection) {
        if (this.optionsTimeouts[mediaConnection.name]) {
            console.debug(`Canceling scheduled client options on ${mediaConnection.clusterLabel}`);
            window.clearInterval(this.optionsTimeouts[mediaConnection.name]);
            delete this.optionsTimeouts[mediaConnection.name];
        }
    }

    private sendOptions(ua: UA, mediaConnection: MediaConnection) {
        //@ts-ignore
        ua.sendOptions(mediaConnection.configuration.uri, null, {
            'eventHandlers': {
                'failed': () => console.error(`Options for ${mediaConnection.clusterLabel} failed.`)
            }
        });
    }

    public destroySession() {
        console.warn(`Attempting to terminate orphaned session: ${this.session?.id}`);
        this.session?.terminate({ status_code: 410, reason_phrase: 'Gone', cause: 'Unavailable' });
        console.warn(`Clearing orphaned session ${this.session?.id}`);
        this.session = null;
        return of(true);
    }
}
