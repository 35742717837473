/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */



import { createAction, props } from "@ngrx/store";
import { ToastType } from "@msi/cobalt";

export const displayToastNotification = createAction(
    '[Notification] Display',
    props<{ message: string, level: ToastType }>()
);
