/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigurationState } from './configuration.reducer';
import { selectStandaloneCallTableColumnsConfiguration } from '../../settings/+state/settings.selectors';

export const CONFIGURATION_FEATURE = 'configuration';

export const selectConfigurationState = createFeatureSelector<ConfigurationState>(CONFIGURATION_FEATURE);

export const selectConfigurationEffectInitialized = createSelector(selectConfigurationState, (state) => state.effectsInitialized);

export const selectCtcConfiguration = createSelector(selectConfigurationState, (state) => state.ctc);

export const selectServerTimeOffset = createSelector(selectConfigurationState, (state) => state.serverTimeOffset);

export const selectUiConfiguration = createSelector(selectConfigurationState, (state) => state?.ui);

export const selectHeadsetRecoverPeriod = createSelector(selectUiConfiguration, (config) => config.headsetDisconnectRecoveryPeriod);

export const selectConnectionRecoverPeriod = createSelector(selectUiConfiguration, (config) => config.systemReconnectPeriod);

export const selectLayoutConfiguration = createSelector(selectConfigurationState, (state) => state?.layouts);

export const selectClusterVersionMap = createSelector(selectConfigurationState, (state) => state.ctcVersion);

export const selectLayoutApplicationHeader = createSelector(selectLayoutConfiguration, (config) => config?.applicationHeader);

export const selectLayoutTheme = createSelector(selectLayoutApplicationHeader, (config) => config?.sessionManager?.theme);

export const selectLayoutLeftPane = createSelector(selectLayoutConfiguration, (config) => config.leftPane);

export const selectLayoutCentralPane = createSelector(selectLayoutConfiguration, (config) => config.centralPane);

export const selectLayoutRightPane = createSelector(selectLayoutConfiguration, (config) => config.rightPane);

export const selectLayoutSidePane = createSelector(selectLayoutConfiguration, (config) => config.sidePane);

export const selectSupervisorTabEnabled = createSelector(selectLayoutCentralPane, (central) => Boolean(central?.monitoring));

export const selectCallListTabEnabled = createSelector(selectStandaloneCallTableColumnsConfiguration, (config) => config?.enabled);

export const selectMetricsEnabled = createSelector(selectSupervisorTabEnabled, (supervisorViewEnabled) => Boolean(supervisorViewEnabled));

export const selectRecentCallLimit = createSelector(selectUiConfiguration, (config) => config.recentCallLimit);

export const selectHistoryDepth = createSelector(selectUiConfiguration, (config) => config?.historyDepth);

export const selectApplicationLinks = createSelector(selectConfigurationState, (config) => config?.links);

export const selectSiteConfiguration = createSelector(selectCtcConfiguration, (config) => config?.siteConfiguration);

export const selectHostName = createSelector(selectSiteConfiguration, (siteConfig) => (siteConfig ? siteConfig.hostname : null));

export const selectClusterConfiguration = createSelector(selectCtcConfiguration, (config) => (config?.clusters ? config.clusters : []));

export const selectClusterNames = createSelector(selectClusterConfiguration, (clusters) => (clusters.map((cluster) => cluster.name)));

export const selectClusterNameToAddressMap = createSelector(selectClusterConfiguration, (clusters) =>
    Object.fromEntries(clusters.filter((cluster) => cluster.ctcAddress).map((cluster) => [cluster.name, `https://${cluster.ctcAddress}`]))
);

export const selectClusterConfigurationMap = createSelector(selectClusterConfiguration, (clusters) =>
    Object.fromEntries(clusters.map((cluster) => [cluster.name, cluster]))
);

export const selectClusterVersions = createSelector(selectClusterVersionMap, selectClusterConfigurationMap, (ctcVersionMap, clusterMap) => {
    return Object.values(clusterMap).map((cluster) => ({ uuid: cluster.uuid, label: cluster.clusterLabel, version: ctcVersionMap[cluster.name]}));
});

export const selectClusterConfigurationByIdMap = createSelector(selectClusterConfiguration, (clusters) =>
    Object.fromEntries(clusters.map((cluster) => [cluster.uuid, cluster]))
);

const _selectCtcStatus = createSelector(selectConfigurationState, (state) => state.ctcStatus);
export const selectCtcOverrideStatus = createSelector(selectConfigurationState, (state) => state.ctcOverrideStatus);

export const selectCtcConnectionOverride = createSelector(selectConfigurationState, (state) => state.ctcConnectionOverride);

export const selectCtcStatus = createSelector(_selectCtcStatus, selectCtcOverrideStatus, (status, overrideStatus) => ({...status, ...overrideStatus}));

export const selectAvailablePrimaryCtc = createSelector(selectCtcStatus, selectClusterConfigurationMap, (ctcStatusMap, clusterMap) =>
    Object.keys(ctcStatusMap).filter((clusterName) => ctcStatusMap[clusterName]).map((clusterName) => ({ clusterName: clusterName, label: clusterMap[clusterName].clusterLabel }))
);

export const selectCtcStatusByName = (clusterName: string) => createSelector(selectCtcStatus, (ctcStatus) => ctcStatus[clusterName]);

export const selectAllCallQueues = createSelector(selectConfigurationState, (state) => (state?.callQueues ? state.callQueues : []));
export const selectCallQueueMap = createSelector(selectAllCallQueues, (callQueues) =>
    Object.fromEntries(callQueues.map((callQueue) => [callQueue.name, callQueue]))
);

export const selectFilteredCallQueues = createSelector(selectConfigurationState, (state) => state.selectedCallQueueFilter);

/* ******************* Hotkeys ****************** */

export const selectHotkeys = createSelector(selectConfigurationState, (state) => state.hotkeys);

/* ******************* Alerts ******************* */

export const selectAudibleAlerts = createSelector(selectConfigurationState, (state) => state.alerts);

export const selectIMAlert = createSelector(selectAudibleAlerts, (audibleAlerts) => audibleAlerts?.imAlert);

export const selectAnnouncementAlert = createSelector(selectAudibleAlerts, (audibleAlerts) => audibleAlerts?.announcementAlert);

export const selectNetworkDisconnectionAlert = createSelector(selectAudibleAlerts, (audibleAlerts) => audibleAlerts?.networkDisconnectionAlert);

export const selectCallAlerts = createSelector(selectAudibleAlerts, (audibleAlerts) => (audibleAlerts?.callAlerts ? audibleAlerts?.callAlerts : []));

export const selectHasInitialized = createSelector(selectConfigurationState, (state) => state.hasInitialized);

export const selectQuiescence = createSelector(selectConfigurationState, (state) => state.quiescence);

