import { Injectable } from '@angular/core';
import {ToastMode, ToastService, ToastOptions, ToastType} from "@msi/cobalt";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    private readonly toastOptions: ToastOptions = {
        autoDismiss: 5000,
        closeButton: true
    };

    constructor(public toastService: ToastService) {
        toastService.updateContainerConfig({
            maxToastsLimit: 3,
            position: 'top-center',
            maxMessageWidth: 400
        });
    }

    public showToast(message: string, level: ToastType) {
        switch (level) {
        case ToastType.success:
            return this.toastService.success(message, undefined, this.toastOptions);
        case ToastType.warning:
            return this.toastService.warning(message, undefined, this.toastOptions);
        case ToastType.error:
            return this.toastService.error(message, undefined, this.toastOptions);
        default:
            return null;
        }
    }
}
